.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease forwards;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.main {
    opacity: 0;
    transition: opacity 1s ease;
}

.main.show {
    opacity: 1;
    animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
