@font-face {
    font-family: 'Firago';
    unicode-range: U+000-5FF;
    src: url('../public/fonts/FiraGO-Regular.woff') format('woff');
}

body {
    margin: 0;
    font-family: 'Firago', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
